var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{attrs:{"id":"home-outer"}},[_c('header',{staticClass:"text-center pb-12"},[_c('img',{staticClass:"mx-auto",staticStyle:{"max-height":"600px"},attrs:{"src":require("@/assets/magpie-vector-v02.svg")}}),_c('p',{staticClass:"text-center font-display text-4xl"},[_vm._v("UofC Information Security Club")]),_c('svg',{staticClass:"animate-bounce w-16 h-16 text-gray-900 mx-auto mt-12",attrs:{"fill":"none","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"d":"M19 14l-7 7m0 0l-7-7m7 7V3"}})])]),_c('div',{staticClass:"home"},[_c('div',{staticClass:"flex flex-col"},[_c('section',{staticClass:"w-full bg-accent-2 py-12"},[_c('p',{staticClass:"text-center font-display text-4xl"},[_vm._v("Who We Are")]),_vm._m(0),_c('div',{staticClass:"w-5/6 md:w-2/3 xl:w-2/5 mx-auto py-12 overflow-hidden"},[_c('div',{staticClass:"border-2 bg-gray-800 rounded-full py-2 px-4 text-xl"},[_c('span',{staticStyle:{"color":"#9289b8"}},[_vm._v("root@magpie ~$ ")]),_c('span',{staticClass:"text-white"},[_vm._v("echo ")]),_c('vue-typer',{attrs:{"text":["SQL injection","privilege escalation","University of Calgary","XXS injection","web exploitation","capture the flag", "ROP chain", "cryptography"],"repeat":Infinity,"shuffle":false,"initial-action":"typing","pre-type-delay":70,"type-delay":70,"pre-erase-delay":2200,"erase-delay":65,"erase-style":"backspace","erase-on-complete":false,"caret-animation":"solid"}})],1)])]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-5/6 md:w-2/3 xl:w-2/5 mx-auto py-12"},[_c('p',{staticClass:"text-center font-body text-lg"},[_vm._v("The mission of the Information Security Club is to expose and educate university students to the technical and theoretical skills in the field of cybersecurity, and to connect these students with industry professionals.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"w-full py-10"},[_c('div',{staticClass:"w-2/3 mx-auto my-4"},[_c('p',{staticClass:"font-display text-center text-4xl"},[_vm._v("Where We Meet")]),_c('p',{staticClass:"font-body text-center text-2xl"},[_vm._v("Join us every Friday at 4PM in-person for our weekly practice sessions in MS325!")]),_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-2/3 mx-auto my-4 text-center"},[_c('p',{staticClass:"text-center font-display text-4xl"},[_vm._v("Email us at:")]),_c('p',{staticClass:"text-center font-display text-3xl md:text-5xl font-bold break-all"},[_vm._v("infosec.ucalgary@gmail.com")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-2/3 mx-auto my-4"},[_c('p',{staticClass:"text-center font-display text-4xl"},[_vm._v("Find Us On Discord")]),_c('a',{attrs:{"href":"https://discord.gg/dRTqKPatTv","target":"_blank"}},[_c('img',{staticClass:"mx-auto",staticStyle:{"width":"40%"},attrs:{"src":require("@/assets/Discord-Logo+Wordmark-Color.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-2/3 mx-auto my-4"},[_c('p',{staticClass:"text-center font-display text-4xl"},[_vm._v("Subscribe on YouTube")]),_c('a',{attrs:{"href":"https://www.youtube.com/channel/UCiDL68PDJUyX-CvoP6FHVcw","target":"_blank"}},[_c('img',{staticClass:"mx-auto",staticStyle:{"width":"40%"},attrs:{"src":require("@/assets/youtube-logo.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-2/3 mx-auto my-4"},[_c('p',{staticClass:"text-center font-display text-4xl"},[_vm._v("Follow us on Twitch")]),_c('a',{attrs:{"href":"https://www.twitch.tv/infosecucalgary","target":"_blank"}},[_c('img',{staticClass:"mx-auto",staticStyle:{"width":"35%"},attrs:{"src":require("@/assets/twitch-logo.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-2/3 mx-auto my-4"},[_c('p',{staticClass:"text-center font-display text-4xl my-6"},[_vm._v("Check Out Our CTF")]),_c('a',{attrs:{"href":"http://magpiectf.ca","target":"_blank"}},[_c('img',{staticClass:"mx-auto rounded-full",staticStyle:{"width":"45%"},attrs:{"src":require("@/assets/CTF2023.png")}})])])
}]

export { render, staticRenderFns }
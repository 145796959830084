<template>
  <div class="text-center">
    <h1>404 - Not Found</h1>
    <p>The page you're looking for does not exist.</p>
  </div>
</template>
<script>
export default {
  name: 'NotFoundComponent'
}
</script>

<template>
  <div id="mc_embed_signup">
    <form action="https://gmail.us5.list-manage.com/subscribe/post?u=2dacfe69a3301915c9296bb02&amp;id=7c6cf3d552" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
      <div id="mc_embed_signup_scroll">
        <p class="text-lg font-bold mb-2">Subscribe to our mailing list</p>
        <div class="mc-field-group">
          <input type="email" value="" name="EMAIL" class="required email border border-gray-500 ml-2 p-1 placeholder-black placeholder-opacity-100" id="mce-EMAIL" placeholder="Email Address">
        </div>
        <div id="mce-responses" class="clear">
          <div class="response" id="mce-error-response" style="display:none"></div>
          <div class="response" id="mce-success-response" style="display:none"></div>
        </div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
        <div style="position: absolute; left: -5000px;" aria-hidden="true">
          <input type="text" name="b_2dacfe69a3301915c9296bb02_7c6cf3d552" tabindex="-1" value="">
        </div>
        <div class="clear">
          <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button text-black bg-blue-500 hover:bg-blue-700 py-1 px-3 rounded mt-2">
        </div>
      </div>
    </form>
  </div>
</template>
<script>
export default {
  name: 'MailchimpComponent'
}
</script>

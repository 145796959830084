<template>
 <section class="text-center w-10/12 md:w-5/6 lg:w-3/5 mx-auto">
  <div class="font-display text-4xl my-4">magpieCTF 2023 Winners</div>
  <div class="mx-auto">
    <table class="w-full">
     <tr class="text-center">
      <th>Place</th>
      <th>Team</th>
      <th>Members</th>
      <th class="hidden md:table-cell">Challenges Solved</th>
      <th class="hidden md:table-cell">Points</th>
      <th class="hidden md:table-cell">School</th>
     </tr>
     <tr>
      <td>1</td>
      <td>YegSecNC</td>
      <td class="text-center">steadmanticore, BooMShanKerX, Siachen, litobro, coryleo</td>
      <td class="hidden md:table-cell">23</td>
      <td class="hidden md:table-cell">3873</td>
      <td class="hidden md:table-cell">NA</td>
     </tr>
     <tr>
      <td>2</td>
      <td>NYUSEC</td>
      <td class="text-center">NYUSEC, XxX</td>
      <td class="hidden md:table-cell">23</td>
      <td class="hidden md:table-cell">3873</td>
      <td class="hidden md:table-cell">New York University</td>
     </tr>
     <tr>
      <td>3</td>
      <td>Looni𝑒</td>
      <td class="text-center">Idleless, WonOhSeven, alphawhh, whoareuwhoamiwhatarewe</td>
      <td class="hidden md:table-cell">22</td>
      <td class="hidden md:table-cell">3453</td>
      <td class="hidden md:table-cell">NA</td>
     </tr>
    </table>
  </div>
  <div class="font-display text-4xl mt-8">Previous Winners</div>
  <!-- note: change text-4xl to text-2xl when copying to here to keep consistency -->
  <div class="font-display text-2xl my-4">magpieCTF 2022 Winners</div>
  <div class="mx-auto">
    <table class="w-full">
     <tr class="text-center">
      <th>Place</th>
      <th>Team</th>
      <th>Members</th>
      <th class="hidden md:table-cell">Challenges Solved</th>
      <th class="hidden md:table-cell">Points</th>
      <th class="hidden md:table-cell">School</th>
     </tr>
     <tr>
      <td>1</td>
      <td>please i want an ipad</td>
      <td class="text-center">youssef, ehhthing, LaushoJ, loluwot</td>
      <td class="hidden md:table-cell">32</td>
      <td class="hidden md:table-cell">15761</td>
      <td class="hidden md:table-cell">University of Toronto, University of Waterloo</td>
     </tr>
     <tr>
      <td>2</td>
      <td>Maple Bacon</td>
      <td class="text-center">Green-Avacado, vvrgdn, apropos, Kevin, aynakeya</td>
      <td class="hidden md:table-cell">29</td>
      <td class="hidden md:table-cell">12830</td>
      <td class="hidden md:table-cell">University of British Columbia</td>
     </tr>
     <tr>
      <td>3</td>
      <td>The Mellow Yellows</td>
      <td class="text-center">UU-Matter, sussywussy, VictorL000, uyxela</td>
      <td class="hidden md:table-cell">27</td>
      <td class="hidden md:table-cell">11667</td>
      <td class="hidden md:table-cell">University of Waterloo</td>
     </tr>
    </table>
  </div>
  <div class="font-display text-2xl my-4">magpieCTF 2021 Winners</div>
  <div class="mx-auto">
    <table class="w-full">
     <tr class="text-center">
      <th>Place</th>
      <th>Team</th>
      <th>Members</th>
      <th class="hidden md:table-cell">Challenges Solved</th>
      <th class="hidden md:table-cell">Points</th>
      <th class="hidden md:table-cell">School</th>
     </tr>
     <tr>
      <td>1</td>
      <td>Proxima</td>
      <td class="text-center">AdiGV, bharatuppal</td>
      <td class="hidden md:table-cell">34</td>
      <td class="hidden md:table-cell">14798</td>
      <td class="hidden md:table-cell">University of Calgary</td>
     </tr>
     <tr>
      <td>2</td>
      <td>Arch Users</td>
      <td class="text-center">Konrad, decimix, nayanprakash</td>
      <td class="hidden md:table-cell">29</td>
      <td class="hidden md:table-cell">12302</td>
      <td class="hidden md:table-cell">University of Alberta</td>
     </tr>
     <tr>
      <td>3</td>
      <td>FUBAR</td>
      <td class="text-center">cozy_mittens, acmkan, SteamBlizzard, Loxreaten, youssef</td>
      <td class="hidden md:table-cell">26</td>
      <td class="hidden md:table-cell">10814</td>
      <td class="hidden md:table-cell">Calgary Area</td>
     </tr>
    </table>
  </div>
 </section>
</template>

<script>

export default {
  name: 'ctf_winners'
}
</script>
